* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container {
    width: fit-content;
    height: fit-content;
    /* border: 4px solid brown; */
    padding: 20px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}
.container .title {
    width: 200px;
    margin: auto;
    align-items: center;
    /* border: 1px solid green; */
    margin-top: 10px;
    margin-bottom: 10px;
}
.container .title h1 {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
}
.container .meal-details {
    margin-top: 20px;
}
.container .meal-details .quantity-adjustment .flex-bowl {
    display: flex;
    width: fit-content;
    margin: auto;
    align-items: center;
    gap: 20px;
}
.container .meal-details h3 {
    font-weight: 600;
    font-family: 20px;
    font-family: 'Merienda';
}
.container .meal-details .quantity-adjustment .bowl-img {
    width: 100px;
}
.container .meal-details .quantity-adjustment .flex-quantity {
    display: none;
    width: fit-content;
    margin: auto;
    padding: 10px;
    gap: 20px;
    margin-bottom: 20px;
}
.container .meal-details .quantity-adjustment .flex-quantity p {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    width: 50px;
}
.container .cards {
    /* border: 2px solid orangered; */
    width: fit-content;
    height: 550px;
    margin-top: 100px;
    overflow-y: auto;
    scrollbar-width: none;
}
.container .cards .card {
    /* border: 2px soliid orangered; */
    width: 300px;
    height: auto; 
    text-align: center;
    cursor: pointer;
    padding-bottom: 20px;
    background: rgb(216,185,233);
    background: linear-gradient(90deg, rgba(216,185,233,0.7904411764705882) 12%, rgba(201,246,236,0.6839985994397759) 86%);
    border-radius: 6px;
    margin-bottom: 20px;
}
.container .cards .card:hover {
    transform: scale(1.05);
}
.container .cards .card h2 {
    font-size: 18px;
    font-weight: 400;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding-top: 20px;
}
.container .cards .card img {
    width: 150px;
    height: 100px;
    margin: auto;
    padding-top: 10px;
    margin-bottom: 10px;
}
.container .cards .card .search-box {
    width: 250px;
    margin: auto;
}
.container .cards .card .search-box input {
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.5);
    width: 250px;
    margin: auto;
    padding: 6px;
    border-radius: 4px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 14px;
}
.container .options {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scrollbar-width: none;
    padding-bottom: 10px;
}
.container .options .onepotmeal, .sepratemeal, .parathameal {
    /* border: 2px solid orange; */
    width: 700px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}
.container .options h3 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
}
.container .options select {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    width: 150px;
    margin-top: 10px;
    padding: 4px;
    font-size: 16px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;
}
.container .options .select-meal {
    border: 2px solid #000;
    width: 300px;
    height: 200px;
}
.container .meal-options {
    /* border: 2px solid green; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    height: fit-content;
    margin-top: 10px;
    margin-left: 10px;
}
.container .meal-options .select-meal {
    /* border: 2px solid purple; */
    margin-top: 20px;
    width: fit-content;
    height: fit-content;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    margin: auto;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.5);
    background: rgb(240,230,246);
    background: linear-gradient(90deg, rgba(240,230,246,0.7904411764705882) 12%, rgba(232,252,248,0.6839985994397759) 86%);
}
.container .meal-options .select-meal h4 {
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.container .meal-options .select-meal .icons-action {
    display: flex;
    gap: 10px;
    margin: auto;
    width: fit-content;
    margin-top: 10px;
}
.container .meal-options .select-meal .icons-action .minus, .plus {
    background-color: gray;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    padding: 4px;
}
.container .meal-options .select-meal .icons-action .plus:hover {
    transform: scale(1.2);
}
.container .meal-options .select-meal .icons-action .minus:hover {
    transform: scale(1.2);
}
.container .cards .card .quantity {
    width: fit-content;
    margin: auto;
    align-items: center;
    margin-top: 20px;
}
.container .cards .card .quantity h4 {
    font-weight: 500;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.container .cards .card  .quantity input {
    width: 100px;
    height: 30px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 2px;
}
.container .cards .card .quantity .flex-bowl {
    display: flex;
    gap: 20px;
    align-items: center;
}
.container .cards .card .quantity .line-img {
    height: auto;
    position: relative;
    margin-top: -30px;
    width: auto;
}

/* calendar */
.days-select {
    /* border: 2px solid red; */
    width: 300px;
}
.days-select .days-contain {
    margin-left: -10px;
    width: fit-content;
    height: 20px;
}
.days-select .dayButton { 
    width: 50px;
    height: 50px;
    /* background-color: rgb(252, 244, 244);
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); */
    background: rgb(181,124,246);
    background: linear-gradient(90deg, rgba(181,124,246,0.9248949579831933) 34%, rgba(116,99,245,0.7792366946778712) 86%);
    border-radius: 50px;
}
.days-select .dayButton:hover {
    transform: scale(1.1);
    background: rgb(181,124,246);
    background: linear-gradient(90deg, rgba(181,124,246,0.9753151260504201) 13%, rgba(239,240,7,0.8044467787114846) 82%);
}
.daymeal-details {
    margin-bottom: 10px;
}