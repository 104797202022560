.meal-search-container {
    font-family: Arial, sans-serif;
    gap: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    width: 450px;
    margin: auto;
    margin-top: 40px;
    height: 600px;
    overflow-y: auto;
    scrollbar-width: none;
}
.meal-search-container .title h1 {
  font-size: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
/* calendar */
.days-select {
    /* margin-top: 50px; */
    width: 300px;
}
.days-select .days-contain {
    margin-left: -15px;
    width: fit-content;
    height: 20px;
}
.days-select .dayButton { 
    width: 50px;
    height: 50px;
    /* background-color: rgb(252, 244, 244);
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); */
    background: rgb(181,124,246);
    background: linear-gradient(90deg, rgba(181,124,246,0.9248949579831933) 34%, rgba(116,99,245,0.7792366946778712) 86%);
    border-radius: 50px;
}
.days-select .dayButton:hover {
    transform: scale(1.1);
    background: rgb(181,124,246);
    background: linear-gradient(90deg, rgba(181,124,246,0.9753151260504201) 13%, rgba(239,240,7,0.8044467787114846) 82%);
}
.getMeal {
  /* background-color: red; */
  width: 250px;
  height: fit-content;
  position: relative;
  text-align: center;
}
.meal-info {
    position: absolute;
    background-color: red;
}
.card {
    width: 300px;
    padding: 20px;
    margin-top: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: max-height 0.3s ease; /* Smoothly adjust card height */
    /* overflow: hidden; */
    scrollbar-width: none;
    background: rgb(216,185,233);
    background: linear-gradient(90deg, rgba(216,185,233,0.7904411764705882) 12%, rgba(201,246,236,0.6839985994397759) 86%);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: -20px;
}
.card img {
    width: 100px;
    margin: auto;
    margin-top: 20px;
}
.search-box input {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 30px;
    padding: 10px;
    position: relative;
    margin-top: 20px;
    border-radius: 4px;
    /* margin-left: 30px */
}
.details-flex {
    display: flex;
    gap: 10px;
    position: relative;
    margin-top: 20px;
    overflow-x: auto;
    width: 250px;
    scroll-behavior: smooth;
    scrollbar-width: none;
}
.details-flex .sepratemeal {
    width: fit-content;
}
  .meal-categories {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  select {
    padding: 8px;
    font-size: 16px;
    margin-bottom: 20px;
    width: 200px;
  }
  

  .suggestions {
    width: 200px;
    position: relative;
    z-index: 10;
  }
  
  .suggestion-item {
    padding: 8px;
    background-color: #f0f0f0;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .suggestion-item:hover {
    background-color: #ddd;
  }
  
  .no-meal-found {
    color: red;
  }
  
  .selected-meals {
    width: 250px;
    position: relative;
    margin-top: 20px;
    text-align: center;
    z-index: 1;
  }
  
  .selected-meals p {
    font-size: 16px;
  }
  
  .save-button {
    margin-top: 20px;
  }
  
  .save-button button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
  
  .save-button button:hover {
    background-color: #45a049;
  }
  
  .save-button button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .suggestions div {
    cursor: pointer;
  }
  .card.active {
    background-color: red;
  }