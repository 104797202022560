.breakfastmeal {
    border: 2px solid black;
    width: 200px;
    height: 150px;
    text-align: center;
    align-items: center;
}
.breakfastmeal h1 {
    cursor: pointer;
}
.breakfastmeal input {
    border: 2px solid #000;
    margin-top: 40px;
}
.meal-details {
    display: flex;
}
.onepotmeal, .sepmeal, .parameal {
    /* border: 2px solid #000; */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.meal-details h2 {
    text-align: center;
}
.meal-info {
    border: 2px solid black;
    width: 200px;
    text-align: center;
}
.suggestions {
    cursor: pointer;
}
.sepratemeal {
    height: fit-content;
}