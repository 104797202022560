@media (max-width: 585px) {
    .menu-container {
        width: 450px;
        height: 100%;
    }
    .daysselect {
        margin-left: 45px;
    }
    .allmenucard {
        margin-left: -25px;
    }
    .detail-flex {
        width: 400px;
    }
    .tabs {
        width: 370px !important;
    }
    .tabs h4 {
        font-size: 14px;
    }
    .meal-details {
        width: 300px;
    }
    .meal-content {
        width: 370px;
    }
    .flex {
        width: 300px;
    }
    .meal-details select {
        width: 140px;
        padding: 2px;
        font-size: 14px;
    }
    .detail-flex p {
        font-size: 12px;
    }
    .detail-flex .search-input {
        width: 250px;
        margin-left: -70px;
        margin-top: 10px;
    }
    .suggestions {
        margin-left: 70px;
    }
}

@media (max-width: 485px) {
    .menu-container {
        width: 400px;
        height: 100%;
    }
    .daysselect {
        margin-left: 25px;
    }
    .allmenucard {
        margin-left: -50px;
    }
    .breakfastmeal {
        width: 300px;
    }
    .breakfastmeal .fleximg-meal {
        width: 280px;
    }
    .breakfastmeal .fleximg-meal video {
        width: 130px;
    }
    .detail-flex {
        width: 350px;
    }
    .tabs {
        width: 320px !important;
    }
    .tabs h4 {
        font-size: 12px;
    }
    .meal-details {
        width: 300px;
    }
    .meal-content {
        width: 320px;
    }
    .flex {
        width: 280px;
    }
    .meal-details select {
        width: 120px;
        padding: 2px;
        font-size: 12px;
    }
    .detail-flex p {
        font-size: 10px;
    }
    .detail-flex .search-input {
        width: 250px;
        margin-left: -130px;
        margin-top: 20px;
        padding: 4px;
    }
    .suggestions {
        margin-left: 40px;
    }
}

@media (max-width: 425px) {
    .menu-container {
        width: 350px;
        height: 100%;
    }
    .daysselect {
        margin-left: 25px;
        width: 300px;
    }
    .allmenucard {
        margin-left: -75px;
    }
    .breakfastmeal {
        width: 250px;
        height: 200px;
    }
    .breakfastmeal .fleximg-meal {
        width: 220px;
    }
    .breakfastmeal .fleximg-meal video {
        width: 100px;
    }
    .search-box .search-input {
        width: 200px;
        padding: 4px;
        margin-top: 6px;
    }
    .breakfastmeal .arrowdown {
        margin-top: -6px;
        font-size: 22px;
    }
    .detail-flex {
        width: 300px;
    }
    .tabs {
        width: 270px !important;
    }
    .tabs h4 {
        font-size: 10px;
    }
    .meal-details {
        width: 280px;
    }
    .meal-content {
        width: 270px;
    }
    .flex {
        width: 240px;
    }
    .meal-details select {
        width: 100px;
        padding: 2px;
        font-size: 12px;
    }
    .detail-flex p {
        font-size: 10px;
    }
    .detail-flex .search-input {
        width: 180px;
        margin-left: -180px;
        margin-top: 20px;
        padding: 4px;
    }
    .suggestions {
        margin-left: 12px;
    }
}