* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.menu-container {
    width: 550px;
    height: fit-content;
    margin: auto;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    /* background-color: #047592; */
    background-color: #fff;
    color: #fff;
}
.title h1 {
    font-size: 24px;
    font-family: sans;
    color: #093414;
    font-weight: 600;
}
.daysselect {
    /* position: fixed; */
    margin-left: 90px;
    width: 360px;
}
.breakfastmeal {
    width: 320px;
    height: fit-content;
    padding: 10px;
    margin: auto;
    position: relative;
    margin-top: 40px;
    /* background-color: #e8e4e4; */
    background-color: #093414;
    color: #fff;
    border: 2px solid #fff;
    /* border: none; */
    font-family: serif;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    transition: height 0.3s ease;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.breakfastmeal.active {
  transform: scale(1.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.breakfastmeal h1 {
    font-size: 20px;
    text-align: center;
}
.breakfastmeal .fleximg-meal {
    display: flex;
    width: 300px;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
}
.breakfastmeal .fleximg-meal img {
    width: 100px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.breakfastmeal .fleximg-meal .mealdetails {
    width: 150px;
    height: fit-content;
}
.breakfastmeal .fleximg-meal .mealdetails h1, p {
    font-size: 16px;
}
.breakfastmeal .searchcontainer input {
    border: none;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    width: 250px;
    padding: 8px;
}
.breakfastmeal .arrowdown {
    margin: auto;
    font-size: 40px;
    cursor: pointer;
}
.detail-flex {
    display: flex;
    position: relative;
    /* background-color: #fff; */
    background-color: #047592;
    width: 500px;
    margin: auto;
    color: #000;
    gap: 10px;
    margin-top: 20px;
}
.contain .cross {
    color: #093414;
    margin: auto;
    margin-top: 10px;
    position: relative;
}
.detail-flex h3 {
    font-size: 16px;
    font-weight: 500;
    font-family: serif;
    color: #fff;
}
.detail-flex .onepotmeal1 {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    width: 160px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
}
.detail-flex .onepotmeal1 select {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    width: 140px;
    padding: 3px;
    border-radius: 4px;
    font-size: 12px;
}
.detail-flex .sepratemeal1 {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
}
.detail-flex .sepratemeal1 select {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    width: 140px;
    padding: 3px;
    font-size: 12px;
    border-radius: 4px;
}
.detail-flex .parathameal1 {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    width: 200px;
    height: fit-content;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
}
.detail-flex .parathameal1 select {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    width: 140px;
    padding: 3px;
    font-size: 12px;
    border-radius: 4px;
}
.detail-flex .search-input {
    width: 350px;
    /* margin-left: 120px; */
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    border: none;
    font-size: 12px;
    padding: 8px;
    border-radius: 2px;
    margin-bottom: 8px;
}
.detail-flex p {
    font-size: 12px;
}
.app {
    font-family: Arial, sans-serif;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .search-box .search-input {
    width: 250px;
    padding: 20px;
    font-size: 16px;
    margin-bottom: 10px;
    border: none;
  }
  
  .suggestions1 {
    width: 280px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 150px;
    overflow-y: auto;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    color: #000;
  }
  
  .suggestion-item1 {
    padding: 10px;
    cursor: pointer;
    color: #000;
  }
  
  .suggestion-item1:hover {
    background-color: #f0f0f0;
  }
  
  /* .meal-details {
    width: 200px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    color: #000;
} */
/* .showonepot {
    width: 460px;
    margin: auto;
    margin-top: 20px;
    position: absolute;
    height: fit-content;
    padding: 10px;
    background-color: rgb(209, 206, 206);
    align-items: center;
}
*/

.tabs {
  width: 450px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  
  .tab {
    padding: 10px;
    text-align: center;
    flex: 1;
    background-color: lightgray;
  }
  
  
  .meal-content {
    /* padding: 20px; */
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('../../../public/assets/images/spring-roll-6760871_1280.jpg');
    object-fit: fill;
    background-size: cover;
    width: 450px;
  }
  .meal-content .meal-details {
    width: 450px;
    padding: 20px;
    margin: auto;
  }
  
  .meal-options-toggle {
    cursor: pointer;
    text-align: center;
  }
  
  .arrow-icon {
    font-size: 20px;
  }
  
  .meal-options-details {
    background-color: #f0f0f0;
    padding: 20px;
  }

  /* display */
  .contents {
    background-color: #047592;
    color: #fff;
    display: none;
  }
  .active-content {
    display: block;
  }
  .active-tabs {
    background-color: white;
    color: #000;
  }

  .active {
    background-color: #047592;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    transform: scale(1);
  }
  .inactive {
    opacity: 0.6;
    color: #047592;
    font-size: 18px;
  }
  .allmenucard {
    scrollbar-width: none;
    scroll-behavior: smooth;
    transition: background-color 0.3s ease;
  }
  
  /* Active section styling */
  .active-section {
    width: 350px;
    margin: auto;
    transition: 0.5s;
    transform: scale(1.15);
    /* animation: zoomIn 0.8s ease-in-out forwards; */
  }  

  .inactive-section {
    transform: scale(0.8);
    opacity: 0.7;
  }
  @keyframes zoomIn {
    0% {
        transform: scale(1.05); /* Start slightly smaller */
        opacity: 0;
    }
    100% {
        transform: scale(1); /* End at normal size */
        opacity: 1;
    }
}

  