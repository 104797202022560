* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-container {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    width: 500px;
    height: fit-content;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    margin: auto;
    margin-top: 20px;
}
.main-container .title h1 {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 2px;
    font-family: serif;
}

.day-selector {
    width: 440px;
    height: 60px;
    margin: auto;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 70px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 10px;
    position: absolute;
    align-items: center;
    scrollbar-width: none;
    /* background-color: #f6f5f5; */
    border-radius: 8px;
    font-family: '';
}

.day-selector::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}
    .days-container {
        display: inline-flex; 
        gap: 10px;
    }
    
    
    .day {
        display: inline-block;
        padding: 10px 15px;
        background-color: #f0f0f0;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    .day-button {
        width: 50px;
        height: 50px;
        background-color: rgb(252, 244, 244);
        background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
        border-radius: 50px;
    }
    .day-button:hover {
        transform: scale(1.2);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: rgb(149,186,251);
        background: linear-gradient(90deg, rgba(149,186,251,0.4963235294117647) 12%, rgba(176,247,142,0.8016456582633054) 86%);
    }
    .day.active {
        background-color: #4CAF50;
        color: white;
    }
    
    .scrollable-content::-webkit-scrollbar {
        display: none;
    }
    
    .scrollable-content {
        scrollbar-width: none;
        display: none;
    }

.breakfast, .snacks, .lunch, .evening-snacks, .dinner {
    width: 250px;
    height: 200px;
    margin: auto;
    margin-top: 20px;
    border-radius: 6px;
    background-color: rgb(252, 244, 244);
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}
.breakfast h1 {
    font-size: 20px;
    font-weight: 400;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 20px;
}
.breakfast .tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: height 0.3s ease-in-out;  
    
}

.breakfast .tabs-container .tabs {
    display: flex;
    width: 250px;
    height: 220px;
    padding-bottom: 20px;
    justify-content: space-between;
    background: rgb(251,149,149);
    background: linear-gradient(90deg, rgba(251,149,149,0.4963235294117647) 12%, rgba(225,247,142,0.8016456582633054) 86%);
    overflow-x: auto;
    position: relative;
    padding-bottom: 10px;
    overflow-y: auto;
}

.breakfast .tabs-container .active-meal {
    margin-top: 120px;
    width: 250px;
    text-align: center;
    margin-left: 0;
    padding: 0px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
}


.breakfast .one-pot-meal {
    text-align: center;
    padding: 10px;
}
.breakfast h2 {
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.breakfast label {
    text-align: center;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.breakfast select {
    background-color: rgba(0, 0, 0, 0.2);
    width: 130px;
    padding: 6px;
}
.breakfast .active-meal {
    width: 200px;
    height: fit-content;
    position: absolute;
    margin-left: 40px;
    text-align: center;
    /* padding: 10px; */
    border-radius: 4px;
    background: rgb(92,253,205);
    background: radial-gradient(circle, rgba(92,253,205,0.6811974789915967) 34%, rgba(178,222,253,0.7344187675070029) 69%);
    margin-top: 20px;
}

/* snacks start */

.snacks h1 {
    font-size: 20px;
    font-weight: 400;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 20px;
}
.snacks .tabs {
    margin-left: -30px;
    width: 300px;
    justify-content: space-between;
    background: rgb(251,149,149);
    background: linear-gradient(90deg, rgba(251,149,149,0.4963235294117647) 12%, rgba(225,247,142,0.8016456582633054) 86%);
    position: absolute;
}
.snacks .one-pot-meal {
    text-align: center;
    padding: 10px;
}
.snacks .one-pot-meal h2 {
    text-align: center;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.snacks label {
    text-align: center;
    display: block;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.snacks select {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 130px;
    padding: 6px;
}
.snacks .active-meal {
    text-align: center;
    width: 200px;
    height: fit-content;
    position: relative;
    margin-left: 40px;
    text-align: center;
    /* padding: 10px; */
    border-radius: 4px;
    background: rgb(92,253,205);
    background: radial-gradient(circle, rgba(92,253,205,0.6811974789915967) 34%, rgba(178,222,253,0.7344187675070029) 69%);
}

/* snacks end */

/* lunch start */

.lunch h1 {
    font-size: 20px;
    font-weight: 400;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 20px;
}
.lunch .tabs {
    display: flex;
    margin-left: -100px;
    width: 450px;
    justify-content: space-between;
    background: rgb(251,149,149);
    background: linear-gradient(90deg, rgba(251,149,149,0.4963235294117647) 12%, rgba(225,247,142,0.8016456582633054) 86%);
    /* padding-bottom: 10px; */
}
.lunch  .one-pot-meal {
    text-align: center;
    padding: 10px;
}
.lunch h2 {
    text-align: center;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.lunch label {
    text-align: center;
    display: block;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.lunch select {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 130px;
    padding: 6px;
}
.lunch .active-meal {
    text-align: center;
    width: 200px;
    height: fit-content;
    position: absolute;
    margin-left: 40px;
    text-align: center;
    /* padding: 10px; */
    border-radius: 4px;
    background: rgb(92,253,205);
    background: radial-gradient(circle, rgba(92,253,205,0.6811974789915967) 34%, rgba(178,222,253,0.7344187675070029) 69%);
}

/* luch end */

/* evening-snacks start */

.evening-snacks h1 {
    font-size: 20px;
    font-weight: 400;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 20px;
}
.evening-snacks .tabs {
    margin-left: -30px;
    width: 300px;
    justify-content: space-between;
    background: rgb(251,149,149);
    background: linear-gradient(90deg, rgba(251,149,149,0.4963235294117647) 12%, rgba(225,247,142,0.8016456582633054) 86%);
    /* padding-bottom: 10px; */
}
.evening-snacks .one-pot-meal {
    text-align: center;
    padding: 10px;
}
.evening-snacks h2 {
    text-align: center;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.evening-snacks label {
    text-align: center;
    display: block;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.evening-snacks select {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 130px;
    padding: 6px;
}
.evening-snacks .active-meal {
    text-align: center;
    width: 200px;
    height: fit-content;
    position: absolute;
    margin-left: 40px;
    text-align: center;
    /* padding: 10px; */
    border-radius: 4px;
    background: rgb(92,253,205);
    background: radial-gradient(circle, rgba(92,253,205,0.6811974789915967) 34%, rgba(178,222,253,0.7344187675070029) 69%);
}

/* evening-snacks end */

/* dinner start */

.dinner h1 {
    font-size: 20px;
    font-weight: 400;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 20px;
}
.dinner .tabs {
    display: flex;
    margin-left: -100px;
    width: 450px;
    justify-content: space-between;
    background: rgb(251,149,149);
    background: linear-gradient(90deg, rgba(251,149,149,0.4963235294117647) 12%, rgba(225,247,142,0.8016456582633054) 86%);
    /* padding-bottom: 10px; */
    /* padding-top: 10px; */
    padding-left: 10px;
    padding-right: 10px;
}
.dinner .one-pot-meal {
    text-align: center;
    padding: 10px;
}
.dinner h2 {
    text-align: center;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.dinner label {
    text-align: center;
    display: block;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.dinner select {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 130px;
    padding: 6px;
}
.dinner .active-meal {
    text-align: center;
    width: 200px;
    height: fit-content;
    position: absolute;
    margin-left: 40px;
    text-align: center;
    /* padding: 10px; */
    border-radius: 4px;
    background: rgb(92,253,205);
    background: radial-gradient(circle, rgba(92,253,205,0.6811974789915967) 34%, rgba(178,222,253,0.7344187675070029) 69%);
}

/* dinner end */

/* allmeal cards */
.allmeal {
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
}
.main-img {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-top: 10px;
    transition: transform 0.3s ease;
}
.main-img:hover {
    margin-top: 20px;
    transform: matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, 0);
}